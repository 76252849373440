/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
import { Fragment, useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Table, TableBody, TableContainer, Paper, TablePagination, Grid, Typography, ListItem, ListItemIcon, ListItemText, Box, List } from '@mui/material';
import './LandingPage.scss';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { getSession, setShipping_list_count, setShipping_list, setPackages, setIsShippingLoading, setShippingCoPoints } from '../../reducers/user-reducers/UserSlicer';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { URL, LANGUAGES, chWtMultiplier } from '../../constants/global-constants';
import { Dialog, Snackbar, ConfirmDialog, AlertDialog } from '../../style-guide';
import { getShipmentCost,ping, getShippingRequestCount, getShippingRequestList, get_params, logout } from '../../reducers/requestHandler';
import EnHeader from '../Header/EnHeader';
import HeHeader from '../Header/HeHeader';
import Forgot from '../Forgot/Forgot';
import ActivateUser from '../ActivateUser/ActivateUser';
import Login from '../Login/Login';
import NewHomeHeader from '../NewHome/NewHomeHeader';
import Personal from '../Personal/Personal';
import BuyMePayment from '../BuyMePayment/BuyMePayment';
import Home from '../Home/Home';
import HowItWorks from '../HowItWorks/HowItWorks';
import UsRetailers from '../UsRetailers/UsRetailers';
import RedboxPricing from '../RedboxPricing/RedboxPricing';
import FAQS from '../FAQS/FAQS';
import ContactUs from '../ContactUs/ContactUs';
import Accounts from '../Accounts/Accounts';
import Footer from '../Footer/Footer';
import Registration from '../Registration/Registration';
import Profile from '../Registration/Profile';
import Store from '../Store/Store';
import Blog from '../aboutNew/Blog';
import Wrapper from './Wrapper';
import AddAddress from '../AddAddress/AddAddress';
import loadBanner from '../../images/homepage/redbox_pres_3.jpg';
import postReg from '../../images/homepage/postReg.png';
import loginPopup from '../../images/homepage/loginPopup.png';
import NewHome from '../NewHome/NewHome';
import MetaTags from 'react-meta-tags';
import About from '../aboutNew/About';
import Compare from '../aboutNew/Compare';
import OrcaReg from '../aboutNew/OrcaReg';
import Status from '../aboutNew/Status';

export const LandingPage = () => {
    const { userInfo, innerContent } = useSelector(state => state.user);
    const { snackbar } = useSelector(state => state.snackbar);
    const navigate = useNavigate();
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const location = useLocation();
    const [activeScreenInreg, setActiveScreenInreg] = new useState('Registeration');
    const [userName, setUserName] = new useState("");
    const [password, setPassword] = new useState("");
    const [isEnable, setEnabled] = new useState(true);
    const [isPackageLoaded, setPackageLoaded] = new useState(false);
    const [hasError, setHasError] = new useState(false);
    const dispatch = useDispatch();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [isLoaded, setIsLoaded] = useState(true);
    const [isPersonal, setIsPersonal] = useState(false);
    const [isBuyMePayment, setIsBuyMePayment] = useState(false);
    const [isRegister, setIsRegister] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [isForgot, setIsForgot] = useState(false);
    const [isActivateUser, setIsActivateUser] = useState(false);
    const [tokenForCh, setTokenForCh] = useState("");
    const [isChangePass, setIsChangePass] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadDlg, setisLoadDlg] = useState(false);
    const [isAddAddress, setIsAddAddress] = useState(false);
    const [isProfile, setIsProfile] = useState(false);
    const [couponList, setCouponList] = new useState([]);
    const doPing = (e) => {
        var sessionId = getCookie("SESSION_ID");
        const params = new URLSearchParams(window.location.search);
        const session = params.get('session');
        if (session) {
            sessionId = session;
        }

        ping(sessionId, function (response) {
            if (response.misc.CODE === 1) {
                setCookie("SESSION_ID", response.session, 30);
                dispatch(getSession(response))
                if (response.session) {
                    var ht = window.location.href;
                    var ht1 = ht.split("?");
                    var ht2 = ht1[0].split("/")
                    var ht3 = ht2[3];
                    if (ht3 == "accounts") {
                        get_shipping_list_count();
                    }
                }
                //navigate("/home")
                //  ping();
            } else {
            }
        })
    }
    const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const logOutUser = (e) => {
        logout(getCookie("SESSION_ID"), function (response) {
            if (response.misc.CODE === 1) {
                setCookie("SESSION_ID", "", 30);
                dispatch(getSession({
                    user: {
                        firstName: ""
                    }
                }))

            } else {
                
            }
            window.location.reload(true);
        })
    }
    const get_shipping_list_count = (e) => {
        return;

    }
    const calculateValues1 = (h, l, w, wt, weightAndVal, index, subcategories3, subcategories4, subcategories5, price, cPer, setValue, calculatedBasePrice, consolidationCharges) => {


        var line66 = false;

       // var chargWt = Number(h) * Number(l) * Number(w) * chWtMultiplier;
        var chargWt = Number(h) * Number(l) * Number(w) /5000;

        var twt = Number( chargWt > wt ? chargWt : wt);

        if (calculatedBasePrice) {
            //twt = Number(calculatedBasePrice);
            twt = Math.min(twt, Number(calculatedBasePrice));
        }

        var minWeight = 0;
        var maxWeight = 0;
        var minVal = 0;
        try {
            minWeight = maxWeight = weightAndVal[0].wt
            weightAndVal.forEach((weightVal) => {
                var x = weightVal.wt;
                if (x > maxWeight) {
                    maxWeight = x;
                }
                if (x < minWeight) {
                    minWeight = x;
                }
            })
        } catch (e) {
            minWeight = 0.25;
            maxWeight = 26.9;
        }


        twt = Number(twt) <= minWeight ? minWeight : (Number(twt) > maxWeight ? maxWeight : Number(twt));
        twt = Number(Number(twt).toFixed(1))
        var transportVal = 0;
       // twt = "0.3"
        for (var j = 0; j < weightAndVal.length; j++) {
            var weightVal = weightAndVal[j];
            if (weightVal.wt >= twt) {
                transportVal = weightVal.val;
                break;
            }
            if (!weightVal.wt && twt == minWeight) {
                transportVal = weightVal.val;
                break;
            }
        }
       /* weightAndVal.forEach((weightVal) => {
            if (weightVal.wt == twt) {
                transportVal = weightVal.val;
            }
            if (!weightVal.wt && twt == minWeight) {
                transportVal = weightVal.val;
            }
        })*/
        var cD = cPer * Number(transportVal) / 100;
        var orgTotalCost = transportVal;
        transportVal = transportVal - cD;
        if (subcategories5.length) {
            line66 = true;
        }
        var cif = Number(transportVal) + Number(price);
        var cCharge = 0;
        var cifX = 0;
        var pTax = 0;
        var pTotal = cif;
        var vat = 0;
        if (Number(price) > 75) {
            cCharge = cif * Number(subcategories3[index]) / 100;
            cifX = cif * (1 + Number(subcategories3[index]) / 100);
            if (line66) {
                cifX *= 1.6;
            }
            pTax = cifX * Number(subcategories4[index]) / 100;
            pTotal = cifX * (1 + Number(subcategories4[index]) / 100);
            //vat = pTotal * 0.18;
            vat = pTotal * 0.18;
        }
        // 

        var addCharges = 0;
        if (line66) {
            addCharges = 40;
        } else {
            if (Number(price) > 100 && Number(price) < 500) {
                addCharges = 6;
            } else if (Number(price) >= 500 && Number(price) < 1000) {
                addCharges = 20;
            } else if (Number(price) >= 1000) {
                addCharges = 75;
            }
        }
        if (Number(h) > 120 || Number(l) > 120 || Number(w) > 120) {
            addCharges = addCharges + 35;
        }

        var insurence = (Number(price) * 3 / 100) < 2 ? 2 : (Number(price) * 3 / 100);
        //var insurence = (Number(transportVal) * 3.5 / 100) < 2 ? 2 : (Number(transportVal) * 3.5 / 100);

        if (consolidationCharges) {
            addCharges = addCharges + Number(consolidationCharges)
            setValue(prev => ({ ...prev, cS: consolidationCharges }));
        }

        var totalInvoice = Number(Number(transportVal).toFixed(1)) + Number(Number(pTax).toFixed(1)) + Number(Number(vat).toFixed(1)) + Number(Number(addCharges).toFixed(1));
        //var totalInvoice = Number(Number(transportVal).toFixed(1)) + Number(Number(pTax).toFixed(1)) + Number(Number(cCharge).toFixed(1)) + Number(Number(vat).toFixed(1)) + Number(Number(addCharges).toFixed(1));

        //setValue(prev => ({ ...prev, totalCost: price }));
        setValue(prev => ({ ...prev, price1: price }));
        setValue(prev => ({ ...prev, transportVal: transportVal }));
        setValue(prev => ({ ...prev, totalInvoice: totalInvoice }));
        setValue(prev => ({ ...prev, cif: cif }));
        setValue(prev => ({ ...prev, customsCharge: cCharge }));
        setValue(prev => ({ ...prev, vat: vat }));
        setValue(prev => ({ ...prev, purchaseTax: pTax - cCharge }));
        //setValue(prev => ({ ...prev, purchaseTax: pTax }));
        setValue(prev => ({ ...prev, insurence: insurence }));

        
        setValue(prev => ({ ...prev, addCharges: addCharges }));
        setValue(prev => ({ ...prev, orgTotalCost: orgTotalCost }));
        setValue(prev => ({ ...prev, pWt: twt }));
        
        setValue(prev => ({ ...prev, wS: calculatedBasePrice==twt ? ("Content form") : ((Number(wt).toFixed(1) == Number(twt) || Number(wt) == Number(twt)) ? "Actual Weight" : "Calculated weight") }));
        /**/
    }
    const getTotalRepackCost = (data) => {
        var total = 0;
        Object.keys(data).map(key => { total += ((key != undefined && key != "undefined") ? Number(data[key]) : 0) })
        return total;
    }
    const getTotalRescanCost = (data) => {
        var total = 0;
        Object.keys(data).map(key => { total += ((key != undefined && key != "undefined") ? Number(data[key]) : 0) })
        return total;
    }
    const calculateValues = (h, l, w, wt, weightAndVal, index, subcategories3, subcategories4, subcategories5, price, cPer, setValue, calculatedBasePrice, consolidationCharges, additionalCharges, shippingCost, sp = 0, repackCost = {}, rescanCost1 = {}, couponD = null) => {

        var rCost = getTotalRepackCost(repackCost)
        var rescanCost = getTotalRescanCost(rescanCost1);
        var line66 = false;

        // var chargWt = Number(h) * Number(l) * Number(w) * chWtMultiplier;
        var chargWt = Number(h) * Number(l) * Number(w) / 5000;

        var twt = Number(chargWt > wt ? chargWt : wt);

        if (calculatedBasePrice) {
            //twt = Number(calculatedBasePrice);
            twt = Math.min(twt, Number(calculatedBasePrice));
        }

        var minWeight = 0;
        var maxWeight = 0;
        var minVal = 0;
        try {
            minWeight = maxWeight = weightAndVal[0].wt
            weightAndVal.forEach((weightVal) => {
                var x = weightVal.wt;
                if (x > maxWeight) {
                    maxWeight = x;
                }
                if (x < minWeight) {
                    minWeight = x;
                }
            })
        } catch (e) {
            minWeight = 0.25;
            maxWeight = 26.9;
        }


        twt = Number(twt) <= minWeight ? minWeight : (Number(twt) > maxWeight ? maxWeight : Number(twt));
        //twt = Number(Number(twt).toFixed(1))
        var transportVal = 0;
        // twt = "0.3"
        if (sp == 0) {
            for (var j = 0; j < weightAndVal.length; j++) {
                var weightVal = weightAndVal[j];
                if (weightVal.wt >= twt) {
                    transportVal = weightVal.val;
                    break;
                }
                if (!weightVal.wt && twt == minWeight) {
                    transportVal = weightVal.val;
                    break;
                }
            }
        } else {
            transportVal = sp;
        }
       /* weightAndVal.forEach((weightVal) => {
            if (weightVal.wt == twt) {
                transportVal = weightVal.val;
            }
            if (!weightVal.wt && twt == minWeight) {
                transportVal = weightVal.val;
            }
        })*/
        var cD = cPer * Number(transportVal) / 100;
        var orgTotalCost = transportVal;
        if (couponD) {
            if (couponD.affiliate) {
                var totalForDeduction = Number(rCost) + Number(transportVal) + Number(rescanCost) + Number(consolidationCharges);
                cD = cPer * totalForDeduction / 100;
            }
        }
        transportVal = transportVal - cD;
        if (subcategories5.length) {
            line66 = true;
        }
        var cif = Number(transportVal) + Number(price);
        var cCharge = 0;
        var cifX = 0;
        var pTax = 0;
        var pTotal = cif;
        var vat = 0;
        var shippingPrice = 0;
        //shippingCost
        if (shippingCost) {
            shippingPrice = Number(wt ? wt : 0) * shippingCost;
        }

        if (Number(price) > 75) {
            cCharge = cif * Number(subcategories3[index]) / 100;
            cifX = cif * (1 + Number(subcategories3[index]) / 100);
            if (line66) {
               // cifX *= 1.6;
            }
            pTax = cifX * Number(subcategories4[index]) / 100;
            pTotal = cifX * (1 + Number(subcategories4[index]) / 100);
            
          //  vat = pTotal * 0.18;
            vat = (price + shippingPrice) * 0.18;
        }
        // 

        var addCharges = 0;
        var fixedFee = 0;
        var isCar = false;
        if (line66) {
            fixedFee = subcategories5[index]?.value;
            //vat = 0;
            cCharge = 0;
            cifX = 0;
            pTax = 0;
            pTotal = cif;
            vat = 0;
            if (subcategories5[index].isCar) {
                if (Number(price) > 75) {
                    cCharge = cif * Number(subcategories3[index]) / 100;
                    cifX = cif * (1 + Number(subcategories3[index]) / 100);
                    pTax = cifX * Number(subcategories4[index]) / 100;
                    pTotal = cifX * (1 + Number(subcategories4[index]) / 100);
                    //vat = pTotal * 0.18;
                    var pr = (price + shippingPrice) * (1 + Number(subcategories3[index]) / 100) * (1 + Number(subcategories4[index]) / 100);
                    vat = pr * 0.18; // ptax and cc is based on this
                    cCharge = (price + shippingPrice) * Number(subcategories3[index]) / 100;
                    cifX = (price + shippingPrice) * (1 + Number(subcategories3[index]) / 100);
                    pTax = cifX * Number(subcategories4[index]) / 100;
                    pTotal = cifX * (1 + Number(subcategories4[index]) / 100);
                    //pTax -= cCharge
                }
            } else {
                if (Number(price) > 75) {                   
                    //vat = cif * 0.18;
                    vat = (price + shippingPrice) * 0.18;
                }
            } 
           // addCharges = 40;
        } else {
            
        }
        var costSurcharge = 0;
        if (Number(price) > 100 && Number(price) < 500) {
            costSurcharge = 6;
            //addCharges = addCharges + 6;
        } else if (Number(price) >= 500 && Number(price) < 1000) {
            costSurcharge = 20;
            //addCharges = addCharges + 20;
        } else if (Number(price) >= 1000) {
            costSurcharge = 25;
            //addCharges = addCharges + 25;
        }
        addCharges += costSurcharge;
        if (Number(h) > 110 || Number(l) > 110 || Number(w) > 110) {
            addCharges = addCharges + 40;
        }

        var insurence = (Number(price) * 3 / 100) < 2 ? 2 : (Number(price) * 3 / 100);
        //var insurence = (Number(transportVal) * 3.5 / 100) < 2 ? 2 : (Number(transportVal) * 3.5 / 100);

        if (consolidationCharges) {
            addCharges = addCharges + Number(consolidationCharges);
            setValue(prev => ({ ...prev, cS: consolidationCharges }));
        }
        
        if (additionalCharges) {
            addCharges = addCharges + Number(additionalCharges);
            setValue(prev => ({ ...prev, aCUsed: additionalCharges }));
        }
        addCharges += rCost + rescanCost;
        //Number(h) * Number(l) * Number(w)
       /* if (Number(h) > 110 || Number(l) > 110 || Number(w) > 110) {
            addCharges = addCharges + 35;
        }*/
      //  var totalInvoice = Number(Number(transportVal).toFixed(1)) + Number(Number(pTax).toFixed(1)) + Number(Number(vat).toFixed(1)) + Number(Number(addCharges).toFixed(1)) + Number(Number(fixedFee).toFixed(1));
        var totalInvoice = Number(Number(transportVal).toFixed(1)) + Number(Number(pTax).toFixed(1)) + Number(Number(cCharge).toFixed(1)) + Number(Number(vat).toFixed(1)) + Number(Number(addCharges).toFixed(1)) + Number(Number(fixedFee).toFixed(1));

        //setValue(prev => ({ ...prev, totalCost: price }));
        setValue(prev => ({ ...prev, costSurcharge: costSurcharge }));
        setValue(prev => ({ ...prev, fixedFee: fixedFee }));
        setValue(prev => ({ ...prev, price1: price }));
        setValue(prev => ({ ...prev, transportVal: transportVal }));
        setValue(prev => ({ ...prev, totalInvoice: totalInvoice }));
        setValue(prev => ({ ...prev, cif: cif }));
        setValue(prev => ({ ...prev, customsCharge: cCharge }));
        setValue(prev => ({ ...prev, vat: vat }));
        setValue(prev => ({ ...prev, rCost: rCost }));
        setValue(prev => ({ ...prev, rescanCostVal: rescanCost }));
        //setValue(prev => ({ ...prev, purchaseTax: pTax - cCharge }));
        setValue(prev => ({ ...prev, purchaseTax: pTax }));
        setValue(prev => ({ ...prev, insurence: insurence }));
        setValue(prev => ({ ...prev, cD: cD }));

        
        setValue(prev => ({ ...prev, addCharges: addCharges }));
        setValue(prev => ({ ...prev, orgTotalCost: orgTotalCost }));
        setValue(prev => ({ ...prev, repackCost: rCost }));
        setValue(prev => ({ ...prev, pWt: twt }));
        
        setValue(prev => ({ ...prev, wS: calculatedBasePrice==twt ? ("Content form") : ((Number(wt).toFixed(1) == Number(twt) || Number(wt) == Number(twt)) ? "Actual Weight" : "Calculated weight") }));
        /**/
    }
    const getShippingCoPickupPointsBySh = () => {

    }
    const keyPressed = (e) => {
        if (e.keyCode === 13) {

        }
    }
    useEffect(() => {
        doPing();




    }, [dispatch, isLogin]);
    const [targetAction, setTargetAction] = useState("");
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = (add, ind) => {
        setOpenOrderModal(true);
    };
    const onHandleCancelModal = () => setOpenOrderModal(false);


    const [openOrderModalRegistration, setOpenOrderModalRegistration] = useState(false);
    const onHandleOpenModalRegistration = (add, ind) => {
        setOpenOrderModalRegistration(true);
    };
    const onHandleCancelModalRegistration = () => setOpenOrderModalRegistration(false);

    const [openOrderModalLogin, setOpenOrderModalLogin] = useState(false);
    const onHandleOpenModalLogin = (add, ind) => {
        setOpenOrderModalLogin(true);
    };
    const onHandleCancelModalLogin = () => setOpenOrderModalLogin(false);




    if (!isLoading) {
        
        setIsLoading(true)
       // if (window.location == "https://redboxparcel.com" || window.location == "https://redboxparcel.com/home")
            //onHandleOpenModal(true)
        /*if (window.location != "https://redboxparcel.com/nbo" || window.location != "https://redboxparcel.com") {
            window.location= "https://redboxparcel.com"
        }*/
        var action = get_params("action")
        var token = get_params("token")
        if (action === 'changePass' && token != "") {
            setIsChangePass(true)
            setIsForgot(true)
            setTokenForCh(token)
        }
        if (action === 'regActivate' && token != "") {
            setIsRegister(true)
            //setIsForgot(true)
            setActiveScreenInreg("OTPVerify")
            setTokenForCh(token)
        }

        if (action === 'registration' && token == "true") {
            setIsRegister(true)
            //setIsForgot(true)
           // setActiveScreenInreg("OTPVerify")
            //setTokenForCh(token)
        }

        window.setTimeout(function () {
            console.log(window.location.href)
            var ht = window.location.href;
            var htArr = ht.split("/")
            if (htArr[htArr.length - 1] === "orcaReg")
                setTargetAction("orcaReg");
            var action = get_params("target")
            if (action) {
                switch (action) {
                    case 'home':
                        navigate("/home");
                        break;
                    case 'newHome':
                        navigate("/new-home");
                        break;
                    case 'FAQS':
                        /*var urlParams = new URLSearchParams(window.location.search);
                        var id = ""
                        if (urlParams.has("id"))
                            id = "?id="+urlParams.get("id");*/
                        navigate("/FAQS");
                        break;
                    case 'store':
                        navigate("/store");
                        break;
                    case 'redboxPricing':
                        navigate("/redboxPricing");
                        break;
                    case 'accounts':
                        navigate("/accounts");
                        break;
                    case 'contactUs':
                        navigate("/contactUs");
                        break;
                    case 'orcaReg':
                        navigate("/orcaReg");
                        setTargetAction("orcaReg");
                        break;
                    case 'login':
                        setOpenOrderModalLogin(true);
                        break;
                    default:
                        navigate("/home");

                }
            }
            if (get_params("id"))
                document.getElementById(get_params("id")).scrollIntoView();
        }, 100);
        
    }


    return (
        <>

            {<Paper sx={{ width: '100%' }}>
                <div id="page" className="hfeed site mm-page mm-slideout">
                    {targetAction !== "orcaReg" && <HeHeader setIsProfile={setIsProfile} setIsAddAddress={setIsAddAddress} logOutUser={logOutUser} setIsRegister={setIsRegister} setActiveScreenInreg={setActiveScreenInreg} setIsLogin={setIsLogin} />}
                    {location.pathname == '/new-home'&&false && <NewHomeHeader setIsProfile={setIsProfile} setIsAddAddress={setIsAddAddress} logOutUser={logOutUser} setIsRegister={setIsRegister} setActiveScreenInreg={setActiveScreenInreg} setIsLogin={setIsLogin} />}
                    {openOrderModal && (
                        <Dialog maxWidth='sm' open={openOrderModal} title={``} onHandleCancel={onHandleCancelModal} >
                            <div style={{ overflow: "hidden", maxWidth: "480px", minHeight: "180px" }}>
                                <Grid container item justifyContent="flex-end" sx={{ fontSize: "20px" }}>
                                    <Button variant='text' sx={{ borderRadius: "25px", padding: "3px" }} onClick={() => {
                                        onHandleCancelModal()
                                    }}>&nbsp;</Button>
                                </Grid>
                                <Grid container item justifyContent="center" sx={{ fontSize: "20px", marginTop: "-30px" }}>
                                    <img src={loadBanner} style={{ width: "480px" }} onClick={() => {
                                        onHandleCancelModal();
                                        if (userInfo.session) {
                                            navigate("/accounts")
                                        } else {
                                            setIsRegister(true)
                                            //navigate("/home")
                                        }
                                    }} />
                                </Grid>
                                {/*<Grid container item justifyContent="flex-start" sx={{ fontSize: "20px", marginTop: "-28%", marginLeft: "42%" }}>
                                    <Button sx={{ width: "300px", height: "90px" }} variant='text' onClick={() => {
                                        onHandleCancelModal();
                                        if (userInfo.session) {
                                            navigate("/accounts")
                                        } else {
                                            setIsRegister(true)
                                            //navigate("/home")
                                        }
                                    }}> &nbsp;</Button>
                                </Grid>*/}
                            </div>
                        </Dialog>
                    )}

                    {openOrderModalRegistration && (
                        <Dialog maxWidth='sm' open={openOrderModalRegistration} title={``} onHandleCancel={onHandleCancelModalRegistration} >
                            <div style={{ overflow: "hidden", maxWidth: "480px", minHeight: (isMobile()?"0":"240px") }}>
                                {false&&<Grid container item justifyContent="flex-end" sx={{ fontSize: "20px" }}>
                                    <Button variant='text' sx={{ borderRadius: "25px", padding: "3px" }} onClick={() => {
                                        onHandleCancelModalRegistration()
                                    }}>&nbsp;</Button>
                                </Grid>}
                                <Grid container item justifyContent="center" sx={{ fontSize: "20px", marginTop: "-30px" }}>
                                    <img src={postReg} style={{ width: "480px" }} onClick={() => {
                                        onHandleCancelModalRegistration();
                                        if (userInfo.session) {
                                            navigate("/accounts")
                                            setIsAddAddress(false)
                                        } else {
                                            setIsRegister(true)
                                            //navigate("/home")
                                        }
                                    }} />
                                </Grid>
                                {<Grid container item justifyContent="flex-start" sx={{ fontSize: "20px", marginTop: "-15%", marginLeft: "43%" }}>
                                    <Button style={{ minWidth: (isMobile() ?"53px": "94px")}}  variant='text' onClick={() => {
                                        onHandleCancelModalRegistration();
                                        if (userInfo.session) {
                                            navigate("/store")
                                            setIsRegister(false)
                                        } else {
                                            setIsRegister(true)
                                            //navigate("/home")
                                        }
                                    }}> &nbsp;</Button>

                                    <Button style={{ minWidth: "94px" }} variant='text' onClick={() => {
                                        onHandleCancelModalRegistration();
                                        if (userInfo.session) {
                                            navigate("/accounts")
                                            setIsRegister(false);
                                        } else {
                                            setIsRegister(true)
                                            //navigate("/home")
                                        }
                                    }}> &nbsp; </Button>
                                </Grid>}
                            </div>
                        </Dialog>
                    )}

                    {openOrderModalLogin && (
                        <Dialog style={{marginLeft:"0", marginRight: "0"}} maxWidth='sm' direction="rtl" open={openOrderModalLogin} title={`ברוך שובך`} onHandleCancel={onHandleCancelModalLogin} >
                            <div style={{ overflow: "hidden", maxWidth: "480px", minHeight: (isMobile()?"0":"240px") }}>
                                <Grid container item justifyContent="center" sx={{ fontSize: "20px", marginTop: "-30px" }}>
                                    <img alt="" src={loginPopup} style={{ width: "480px" }} onClick={() => {
                                        onHandleCancelModalLogin();
                                        if (userInfo.session) {
                                            navigate("/accounts")
                                            setIsAddAddress(false)
                                        } else {
                                            setIsRegister(true)
                                            //navigate("/home")
                                        }
                                    }} />
                                    <Button style={{ color: "red", fontWeight: "bold", fontSize: "22px" }} variant='text' onClick={() => {
                                        onHandleCancelModalLogin()
                                    }}> { `סגור`} </Button>
                                </Grid>
                            </div>
                        </Dialog>
                    )}
                    {<div className="inner_content" id="inner_content" style={{ paddingTop: targetAction === "orcaReg" ? "0 !important" : "82px" }}>
                        <div id="innerContent" className="">
                            <Wrapper>
                                <Routes>
                                    {/*<Route exact path='/*' element={<Home setIsLogin={setIsLogin} setIsRegister={setIsRegister} />} />*/}
                                    <Route exact path='/*' element={<NewHome setIsLogin={setIsLogin} setIsRegister={setIsRegister} />} />
                                    <Route exact path='/new-home' element={<NewHome  setIsLogin={setIsLogin} setIsRegister={setIsRegister} />} />
                                    <Route exact path='/howItWorks' element={<HowItWorks />} />
                                    <Route exact path='/usRetailers' element={<UsRetailers />} />
                                    <Route exact path='/redboxPricing' element={<RedboxPricing  calculateValues={calculateValues} setIsRegister={setIsRegister} setActiveScreenInreg={setActiveScreenInreg} />} />
                                    <Route exact path='/FAQS' element={<FAQS setIsLogin={setIsLogin} setActiveScreenInreg={setActiveScreenInreg} setIsRegister={setIsRegister} />} />
                                    <Route exact path='/contactUs' element={<ContactUs setActiveScreenInreg={setActiveScreenInreg} setIsLogin={setIsLogin} setIsRegister={setIsRegister} />} />
                                    <Route exact path='/accounts' element={<Accounts isBuyMePayment={isBuyMePayment} setIsBuyMePayment={setIsBuyMePayment} setIsProfile={setIsProfile} couponList={couponList} setCouponList={setCouponList} setIsLogin={setIsLogin} setActiveScreenInreg={setActiveScreenInreg} setIsRegister={setIsRegister} setIsAddAddress={setIsAddAddress} setIsPersonal={setIsPersonal} />} />
                                    <Route exact path='/orcaReg' element={<OrcaReg isBuyMePayment={isBuyMePayment} setIsChangePass={setIsChangePass} setIsForgot={setIsForgot} setIsBuyMePayment={setIsBuyMePayment} setIsProfile={setIsProfile} couponList={couponList} setCouponList={setCouponList} setIsLogin={setIsLogin} setActiveScreenInreg={setActiveScreenInreg} setIsRegister={setIsRegister} setIsAddAddress={setIsAddAddress} setIsPersonal={setIsPersonal} />} />
                                    <Route exact path='/ParcelStatus' element={<Status />} />
                                    <Route exact path='/store' element={<Store couponList={couponList} setCouponList={setCouponList} setIsLogin={setIsLogin} setActiveScreenInreg={setActiveScreenInreg} setIsRegister={setIsRegister} setIsPersonal={setIsPersonal} />} />
                                    <Route exact path='/comparePrices' element={
                                        <>
                                            <MetaTags id="comparePrices">
                                                <title>Compare Prices </title>
                                            </MetaTags>
                                            <Compare setIsLogin={setIsLogin} setActiveScreenInreg={setActiveScreenInreg} setIsRegister={setIsRegister} setIsPersonal={setIsPersonal} />
                                        </>} />
                                    <Route exact path='/about_1' element={<>
                                        <MetaTags id="about_1">
                                            <title>About Us </title>
                                        </MetaTags>
                                        {/*<iframe src="https://redboxparcel.com/new_page_25.html" frameBorder="0" style={{ "height": "170vh", "width": "100%", "top": "0px", "left": "0px", "right": "0px", "bottom": "0px" }} />
                                        */}
                                        <About setIsLogin={setIsLogin} setActiveScreenInreg={setActiveScreenInreg} setIsRegister={setIsRegister} setIsPersonal={setIsPersonal} /> </> } />
                                    <Route exact path='/blog' element={<Blog />} />
                                    {/*<Route exact path='/accounts' element={<Accounts setIsLogin={setIsLogin} setActiveScreenInreg={setActiveScreenInreg} setIsRegister={setIsRegister} setIsPersonal={setIsPersonal} />} />*/}

                                </Routes>
                            </Wrapper>
                        </div>
                        {targetAction !== "orcaReg" && 
                            < footer className="site-footer" id="main_page_footer">
                        <Footer setIsRegister={setIsRegister} />
                    </footer>
                    }
                    </div>}
                </div>

            </Paper>
            }
            {snackbar && (
                /* snackbar<Snackbar
                     open={!!snackbar}
                     message={snackbar.message}
                     type={snackbar.type}
                     onClose={onSnackbarHandleClose}
                 />*/
                <AlertDialog
                    open={!!snackbar}
                    message={snackbar.message}
                    type={snackbar.type}
                    onHandleCancel={onSnackbarHandleClose}
                />/**/
            )}
            {isRegister && <Registration setOpenOrderModalRegistration={setOpenOrderModalRegistration} tokenForCh={tokenForCh} setIsAddAddress={setIsAddAddress} setIsChangePass={setIsChangePass} setActiveScreenInreg={setActiveScreenInreg} activeScreenInreg={activeScreenInreg} isRegister={isRegister} setIsRegister={setIsRegister} setIsLogin={setIsLogin} />}
            {isLogin && <Login setIsActivateUser={setIsActivateUser} setIsChangePass={setIsChangePass} setIsPersonal={setIsPersonal} isLogin={isLogin} setIsLogin={setIsLogin} setIsRegister={setIsRegister} setIsForgot={setIsForgot} get_shipping_list_count={get_shipping_list_count} />}
            {isActivateUser && <ActivateUser setActiveScreenInreg={setActiveScreenInreg} tokenForCh={tokenForCh} isChangePass={isChangePass} setIsChangePass={setIsChangePass} isForgot={isForgot} isActivateUser={isActivateUser} setIsLogin={setIsLogin} setIsForgot={setIsForgot} setIsRegister={setIsRegister} setIsActivateUser={setIsActivateUser} />}
            {isForgot && <Forgot tokenForCh={tokenForCh} isChangePass={isChangePass} setIsChangePass={setIsChangePass} isForgot={isForgot} setIsLogin={setIsLogin} setIsForgot={setIsForgot} setIsRegister={setIsRegister} />}
            {isPersonal && <Personal calculateValues={calculateValues} couponList={couponList} get_shipping_list_count={get_shipping_list_count} isPersonal={isPersonal} setIsPersonal={setIsPersonal} />}
            {isBuyMePayment && <BuyMePayment isBuyMePayment={isBuyMePayment} calculateValues={calculateValues} couponList={couponList} get_shipping_list_count={get_shipping_list_count} isPersonal={isPersonal} setIsBuyMePayment={setIsBuyMePayment} />}
            {isAddAddress && <AddAddress isAddAddress={isAddAddress} setIsProfile={setIsProfile} setIsAddAddress={setIsAddAddress} />}
            {isProfile && <Profile doPing={doPing} isProfile={isProfile} setIsAddAddress={setIsAddAddress} setIsProfile={setIsProfile} />}
           
        </>
    );
}

export default LandingPage;