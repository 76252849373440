/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
import { Fragment, useCallback, useEffect, useState } from 'react';
import React from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Button, Table, TableBody, TableContainer, Paper, Grid, Checkbox, FormControlLabel, Box, FormLabel } from '@mui/material';
import { SelectField, TextField, MultilineField } from '../../style-guide';
import './AddAddress.scss';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { getSession } from '../../reducers/user-reducers/UserSlicer';
import { validateHebrewText,saveProfile, ping, validatePhone, listWarehouse, validateEngText } from '../../reducers/requestHandler';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { URL, LANGUAGES } from '../../constants/global-constants';
import { Dialog, Snackbar } from '../../style-guide';
import logo from '../../images/logo.png'
import background from '../../images/title/contact-title.jpg'
import loginImage from '../../images/title/login.jpg'
import CloseIcon from '@mui/icons-material/Close';
import ActionButton from '../Common/ActionButton';
import DialogWithSideBar from '../../style-guide/Dialog/DialogWithSideBar';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import addresses_cities from '../res.json';

const addData = {
    addName: '',
    addPhone: '',
    city: '',
    street: '',
    building: '',
    zipCode: '',
    isDefault: true,
    isUpdate: false,
}

export const AddAddress = (props) => {
    const { isAddAddress, setIsAddAddress, setIsProfile } = props;
    const { userInfo, innerContent } = useSelector(state => state.user);
    const { snackbar } = useSelector(state => state.snackbar);
    const navigate = useNavigate();
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const [warehouseList, setWarehouseList] = useState([]);

    const [indexTodel, setIndexTodel] = new useState(0);
    const [addressNew, setAddNew] = new useState({});
    const [userName, setUserName] = new useState("");
    const [session, setSession] = new useState(getCookie("SESSION_ID"));
    const [isEnable, setEnabled] = new useState(true);
    const [isPackageLoaded, setPackageLoaded] = new useState(false);
    const [address, setAddress] = new useState(addData);
    const [hasError, setHasError] = new useState(false);
    const [addressList, setAddressList] = new useState(userInfo.user.setting?.addressList);
    const dispatch = useDispatch();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [isLoaded, setIsLoaded] = useState(true);
    const [isAdd, setIsAdd] = useState(false);
    const [addCities, setAddCities] = useState([]);
    const [addStreets, setAddStreets] = useState([]);
    const getWarehouses = () => {
        listWarehouse(function (response) {
            if (response.misc.CODE === 1) {
                setWarehouseList(response.data);
                var u = JSON.parse(JSON.stringify(userInfo.user));
                u.sessionId = userInfo.session;
                var addList = [];
                response.data.forEach(function (country) {
                    if (country.name != "פולין" && country.params?.active) {
                        console.log(country)
                        var a = {
                            addName: userInfo.user.firstName,
                            addPhone: userInfo.user.phone,
                            city: country.city,
                            street: country.address,
                            building: '',
                            zipCode: country.zip,
                            isDefault: false,
                            isUpdate: false,
                        }
                        //  setAddress(a)
                        addList.push(a);

                    }
                });
                if (addList.length>0)
                    addList[0].isDefault = true;
                if (u.setting.addressList.length==0 && false) {
                    u.setting.addressList = addList;
                    saveProfile(u, function (res) {
                        setAddressList(res.user.setting.addressList)
                        ping(userInfo.session, function (response) {
                            if (res.misc.CODE === 1) {
                                setIsAdd(false)
                                setCookie("SESSION_ID", res.session, 30);
                                dispatch(getSession(res))
                            } else {
                                //
                            }
                        })
                    });
                }
            } else {
                dispatch(snackbarToggle({ type: 'error', message: "הפעלה נכשלה." }));
            }
        });
    }
    const addAddress = () => {
        var u = JSON.parse(JSON.stringify(userInfo.user));
        u.sessionId = userInfo.session;
        var addList = [];
        if (!u.setting.addressList) {
            u.setting.addressList = [];
        }
        if (address.isDefault && !address.isUpdate) {
            u.setting?.addressList.map((add) => {
                var ad1 = JSON.parse(JSON.stringify(add));
                ad1.isDefault = false;
                addList.push(ad1);
            })
        } else {
            addList = u.setting.addressList
        }

        if (address.isUpdate) {
            for (var i = 0; i < addList.length; i++) {
                if (i == address.index) {
                    address.isUpdate = false;
                    addList[i] = address;
                    break;
                }
            }
        } else {
            addList.push(address);
        }

        u.setting.addressList = addList;
        saveProfile(u, function (res) {
            setAddressList(res.user.setting.addressList)
            ping(userInfo.session, function (response) {
                if (res.misc.CODE === 1) {
                    setIsAdd(false)
                    setCookie("SESSION_ID", res.session, 30);
                    dispatch(getSession(res))
                } else {
                    //
                }
            })
        });
    }
    useEffect(() => {
        getWarehouses();

        var cties = Object.keys(addresses_cities);
        var cts = [];
        for (var i1 = 0; i1 < cties.length; i1++) {
            cts.push({ id: i1, name: cties[i1], label: cties[i1] })
        }
        setAddCities(cts);
        var strts = addresses_cities["" + cties[0]];
        var sts = [];
        for (var i2 = 0; i2 < strts.length; i2++) {
            sts.push({ id: i2, name: strts[i2], label: strts[i2] })
        }
        setAddStreets(sts);
    }, [userInfo]);
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = (add,ind) => {
        setOpenOrderModal(true);
        setAddNew(add);
        setIndexTodel(ind);
    };
    const onHandleCancelModal = () => setOpenOrderModal(false);

    return (
        <DialogWithSideBar
            open={isAddAddress}
            onClose={() => {
                setIsAddAddress(false);
                //setIsProfile(true);
            }}
            isOutClose={true}
        >

            <Fragment>
                <Box container className="reg_popup_data" >
                    <Box id="add_form" item lg={12} className="popup_tittle" justifyContent="center" sx={{ fontSize: "24px" }}>
                        {/* {'כתובות למשלוח'}*/}
                        אנא הזן כתובת למשלוח בישראל, יש באפשרותך להזין מספר כתובות במידת הצורך
                    </Box>
                    {!isAdd && <Box className='form-action'>
                        <ActionButton className={'submit-action mt-24'} textLabel={`+ הוספת כתובת`} onClick={() => {
                            setIsAdd(true);
                            setAddress(addData);
                        }} sx={{ height: "40px !important", width: "50% !important", fontSize: "18px !important" }} />
                    </Box>}
                    {isAdd && <Box>
                        <Box>
                            <TextField
                                className='text-field'
                                required
                                dir="rtl"
                                value={address.addName}
                                onChange={event => setAddress(prev => ({ ...prev, addName: event.target.value }))}
                                label="שם"
                               // onBlur={validateEngText(address.addName)}
                            />
                            {/*{!validateEngText(address.addName) && address.addName && <Box className='col-md-12' style={{ direction: "rtl", color: "red", top: "-20px" }}>
                              Enter English text only
                            </Box>
                            }*/}
                        </Box>
                        <Box>
                            <TextField
                                className='text-field'
                                required
                                dir="rtl"
                                value={address.addPhone}
                                onChange={event => setAddress(prev => ({ ...prev, addPhone: event.target.value }))}
                                label="מספר נייד"
                            />
                            {!validatePhone(address.addPhone) && address.addPhone && <Box className='col-md-12' style={{ direction: "rtl", color: "red", top: "-20px" }}>
                                מספר טלפון לא תקין
                            </Box>
                            }
                        </Box>
                        <Box>
                        <SelectField
                            className='fullWidthSelection'
                            required
                            value={address.cityId ? address.cityId : null}
                            lg={12}
                            sx={{ margin: "2px" }}
                            style={{ direction: "rtl" }}
                            onChange={event => {
                                var city = addCities.find(u => u.id === event.target.value);
                                if (!city) {
                                    setAddress(prev => ({ ...prev, city: "" }))
                                    setAddress(prev => ({ ...prev, street: "" }))
                                    setAddress(prev => ({ ...prev, cityId: 0, streetId: 0 }));
                                    return;
                                }
                                setAddress(prev => ({ ...prev, city: city.name }));
                                setAddress(prev => ({ ...prev, cityId: event.target.value }));

                                var strts = addresses_cities["" + city.name];
                                var sts = [];
                                for (var i2 = 0; i2 < strts.length; i2++) {
                                    sts.push({ id: i2, name: strts[i2], label: strts[i2] });
                                }
                                setAddStreets(sts);
                                setAddress(prev => ({ ...prev, street: sts[0].name }));
                                setAddress(prev => ({ ...prev, streetId: 0 }));
                            }}
                            options={addCities}
                            islabelAnimate={false}
                            parentClassName={'select-field'}
                            label="עיר"
                        />
                            {/*<TextField
                                className='text-field'
                                required
                                dir="rtl"
                                value={address.city}
                                onChange={event => setAddress(prev => ({ ...prev, city: event.target.value }))}
                                label="עיר"
                            />*/}
                            {!validateHebrewText(address.city) && address.city && <Box className='col-md-12' style={{ direction: "rtl", color: "red", top: "-20px" }}>
                                המערכת תומכת בכתובת בעברית
                            </Box>
                            }
                        </Box>
                        <Box>
                            <SelectField
                                className='text-field'
                                required
                                value={address.streetId}
                                lg={12}
                                sx={{ margin: "2px" }}
                                onChange={event => {
                                    var street = addStreets.find(u => u.id === event.target.value);
                                    if (!street) {
                                        setAddress(prev => ({ ...prev, streetId: 0 }))
                                        setAddress(prev => ({ ...prev, street: "" }))
                                        return;
                                    }
                                    setAddress(prev => ({ ...prev, streetId: event.target.value }));
                                    setAddress(prev => ({ ...prev, street: street.name }));
                                }}
                                options={addStreets}
                                islabelAnimate={false}
                                parentClassName={'select-field'}
                                label="רחוב"
                            />
                            {/*<TextField
                                className='text-field'
                                required
                                dir="rtl"
                                value={address.street}
                                onChange={event => setAddress(prev => ({ ...prev, street: event.target.value }))}
                                label="רחוב"
                            />*/}
                            {!validateHebrewText(address.street) && address.street && <Box className='col-md-12' style={{ direction: "rtl", color: "red", top: "-20px" }}>
                                המערכת תומכת בכתובת בעברית
                            </Box>
                            }
                        </Box>
                        <Box>
                            <TextField
                                className='text-field'
                                required
                                dir="rtl"
                                value={address.building}
                                onChange={event => {
                                    if (Number(event.target.value) <= 9999)
                                        setAddress(prev => ({ ...prev, building: event.target.value }))
                                }}
                                type="number"
                                label='מספר'
                            />
                        </Box>
                        <Box>
                            <TextField
                                className='text-field'
                                
                                dir="rtl"
                                value={address.zipCode}
                                onChange={event => setAddress(prev => ({ ...prev, zipCode: event.target.value }))}
                                label='מיקוד'
                                type="number"
                            />
                            {address.zipCode.length > 7 && <Box className='col-md-12' style={{ direction: "rtl", color: "red", top: "-20px" }}>
                                Zip code should be less than 8 degits
                            </Box>
                            }
                        </Box>
                        <Box>
                            <TextField
                                className='text-field'
                                dir="rtl"
                                value={address.comment}
                                onChange={event => {
                                    //if (Number(event.target.value) <= 9999)
                                    setAddress(prev => ({ ...prev, comment: event.target.value }))
                                }}
                                label="מספר דירה , קומה וכניסה"
                            />
                        </Box>
                        <Box container className='checkboxs' sx={{ mt: 2, ml: -2 }}>
                            <FormControlLabel className="check-box" key={uuidv4()} control={< Checkbox checked={address.isDefault} onChange={event => setAddress(prev => ({ ...prev, isDefault: event.target.checked }))} />} label="כתובת ראשית" />
                        </Box>
                        <Box className='form-action' sx={{ justifyContent: "space-between", display: "flex" }}>

                            <ActionButton sx={{ height: "40px !important", width: "20% !important", fontSize: "18px !important" }} className={'submit-action mt-24'} textLabel={`בטל`} onClick={() => { setIsAdd(false) }} />
                            <ActionButton disabled={!validateHebrewText(address.city) || !validateHebrewText(address.street) || !validatePhone(address.addPhone) || address.zipCode.length > 7} sx={{ height: "40px !important", width: "20% !important", fontSize: "18px !important" }} className={'submit-action mt-24'} textLabel={`שמירה`} onClick={() => { addAddress() }} />
                        </Box>
                    </Box>}
                    <Box>
                        {addressList?.map((add, index) => <Grid key={uuidv4()} container className='address_card dir-rtl'>
                            {<Grid container className="default_status">
                                {!add.isDefault ? 'כתובת משנית' :'כתובת ראשית'}    <Button className='add_as_default default_current' onClick={() => {
                                    var u = JSON.parse(JSON.stringify(userInfo.user));
                                    u.sessionId = userInfo.session;
                                    var addList = [];
                                    u.setting.addressList.map((m, i) => {

                                        if (index == i) {
                                            m.isDefault = true;
                                        } else {
                                            m.isDefault = false;
                                        }
                                        addList.push(m)
                                    })
                                    u.setting.addressList = addList;
                                    saveProfile(u, function (res) {
                                        setAddressList(res.user.setting.addressList)
                                        ping(userInfo.session, function (response) {
                                            if (res.misc.CODE === 1) {
                                                setIsAdd(false)
                                                setCookie("SESSION_ID", res.session, 30);
                                                dispatch(getSession(res))
                                            } else {
                                                //
                                            }
                                        })
                                    });
                                }}>
                                    {add.isDefault ? <CheckCircleOutlineIcon sx={{ fill: "green !important" }} /> : <HighlightOffIcon fill="red" />}
                                </Button>
                            </Grid>
                            }
                            <Grid item xs={3} className="address_row label">שם</Grid>
                            <Grid item xs={9} className="address_row">{add.addName}</Grid>

                            <Grid item xs={3} className="address_row label">מספר נייד</Grid>
                            <Grid item xs={9} className="address_row">{add.addPhone}</Grid>

                            <Grid item xs={3} className="address_row label">עיר</Grid>
                            <Grid item xs={9} className="address_row">{add.city}</Grid>
                            <Grid item xs={3} className="address_row label">רחוב</Grid>
                            <Grid item xs={9} className="address_row">{add.street}</Grid>
                            <Grid item xs={3} className="address_row label">מספר</Grid>
                            <Grid item xs={9} className="address_row">{add.building}</Grid>
                            <Grid item xs={3} className="address_row label">מיקוד</Grid>
                            <Grid item xs={9} className="address_row">{add.zipCode}</Grid>
                            <Grid item xs={3} className="address_row label">{ `הערות`}</Grid>
                            <Grid item xs={9} className="address_row">{add.comment}</Grid>
                            <Grid container className='address_actions'>
                                {<Button onClick={() => {
                                    setIsAdd(true);
                                    setAddress({
                                        index: index,
                                        isUpdate: true,
                                        city: add.city,
                                        addName: add.addName,
                                        addPhone: add.addPhone,
                                        street: add.street,
                                        building: add.building,
                                        zipCode: add.zipCode,
                                        isDefault: add.isDefault,
                                        comment: add.comment,
                                    });
                                    document.getElementById('add_form').scrollIntoView();
                                }}>עריכה <EditLocationAltIcon /> </Button>/**/}
                                <Button onClick={() => { onHandleOpenModal(add,index)} }>מחיקה <HighlightOffIcon /> </Button>
                            </Grid>
                        </Grid>
                        )}
                        {/*<Grid container className='address_card dir-rtl'>
                            <Grid container className="default_status">
                                Default: <Button className='add_as_default'>
                                    <CheckCircleOutlineIcon />
                                </Button>
                            </Grid>
                            <Grid item xs={3} className="address_row label">City:</Grid>
                            <Grid item xs={9} className="address_row">{'New Delhi'}</Grid>
                            <Grid item xs={3} className="address_row label">Street:</Grid>
                            <Grid item xs={9} className="address_row">{'South Extn., Ring Road'}</Grid>
                            <Grid item xs={3} className="address_row label">Building:</Grid>
                            <Grid item xs={9} className="address_row">{'New Opera House'}</Grid>
                            <Grid item xs={3} className="address_row label">Zip Code:</Grid>
                            <Grid item xs={9} className="address_row">{'110051'}</Grid>
                            <Grid container className='address_actions'>
                                <Button>Edit <EditLocationAltIcon /> </Button>
                                <Button>Remove <HighlightOffIcon /> </Button>
                            </Grid>
                        </Grid>*/}
                    </Box>
                    {openOrderModal && (
                        <Dialog maxWidth='sm' open={openOrderModal} title={``} onHandleCancel={onHandleCancelModal}>

                            <Grid container item justifyContent="center" sx={{ fontSize:"20px" }}>
                               ! נתונים אלו ימחקו <br/>
                                ? האם להמשיך
                            </Grid>
                            <Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px" }} onClick={() => {
                                var u = JSON.parse(JSON.stringify(userInfo.user));
                                u.sessionId = userInfo.session;
                                u.setting.addressList.splice(indexTodel, 1);
                                if (addressNew.isDefault && u.setting.addressList.length > 0) {
                                    u.setting.addressList[0].isDefault = true;
                                }
                                saveProfile(u, function (res) {
                                    onHandleCancelModal()
                                    setAddressList(res.user.setting.addressList)
                                    ping(userInfo.session, function (response) {
                                        if (res.misc.CODE === 1) {
                                            setIsAdd(false)
                                            setCookie("SESSION_ID", res.session, 30);
                                            dispatch(getSession(res))
                                        } else {
                                            //
                                        }
                                    })
                                });
                            }}>מחק</Button>
                            <Button sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px" }} variant='contained' onClick={onHandleCancelModal}> בטל</Button>
                        </Dialog>
                    )}
                </Box>
            </Fragment>
        </DialogWithSideBar>
    );
}

export default AddAddress;