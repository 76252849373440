/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Fragment, useState, useEffect } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Button, Box, Grid, Checkbox, FormControlLabel } from '@mui/material';
import { TextField } from '../../style-guide';
import ActionButton from '../Common/ActionButton';

import '../Personal/Personal.scss'
import '../Header/Header.scss';
import '../LandingPage/LandingPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { get_params, getParcelStatusAPI, validatePhone } from '../../reducers/requestHandler';

import { FaCheckCircle, FaCheck, FaWifi, } from "react-icons/fa";


export const Status = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [selectedShipping, setSelectedShipping] = useState(null);
	const [phone, setPhone] = useState("");
	const [hawb, setHawb] = useState("");
	const [showSearch, setShowSearch] = useState(true);
	const [buttonDissabled, setButtonDissabled] = useState(true);

	const resetParams = (dt) => {
		if (dt.paramsNew)
			dt.params = JSON.parse(dt.paramsNew);
		setSelectedShipping(dt);
	}

	const getDateFormat = (e) => {
		var date = new Date(e);
		var dat = ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()));
		var month = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)));
		var date_formated = dat + '/' + month + '/' + date.getFullYear().toString().slice(-2) + "  " + date.getHours() + ":" + (date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes());
		return date_formated
	}
	const getStatusById = (id) => {

		if (id == 1) {
			return true;
		}
		if (id == 2) {

			for (var i = 0; i < selectedShipping?.params?.statusLog?.length; i++) {
				if (selectedShipping?.params?.statusLog[i].id == id) {
					return selectedShipping?.params?.statusLog[i].DATE ? selectedShipping?.params?.statusLog[i].DATE : false;
				}
			}
			return true;
		}
		if (id == 3) {
			if (selectedShipping?.params?.shippingStatus)
				return true;
			return false;
		}
		for (var i = 0; i < selectedShipping?.params?.statusLog?.length; i++) {
			if (selectedShipping?.params?.statusLog[i].id == id) {
				return selectedShipping?.params?.statusLog[i].DATE;
			}
		}
		return false;
	}
	useEffect(() => {
		var ph = get_params("phone");
		var hb = get_params("hawb");
		if (ph && hb) {
			var data = { phone: ph, hawb: hb }
			setShowSearch(false);
			getParcelStatusAPI(data, function (dt) {
				resetParams(dt);

			});
		}
	}, [dispatch]);

	return (
		<Fragment>
			<Grid id="testingheader" item container style={{ width: "100vw" }} justifyContent="center">
				<Grid item container style={{ width: "400px", height: "100%" }}>
					{<Grid item container style={{marginTop: "20px"}}>
						<Grid item container>
							<TextField
								className='text-field'
								required
								tabIndex={"2"}
								value={hawb}
								onChange={event => {
									setHawb(event.target.value)
								}}
								label={`מספר חבילה`}
							/>
						</Grid>
						<Grid item container>
							<TextField
								className='text-field'
								required
								tabIndex={"1"}
								value={phone}
								onChange={event => {
									setPhone(event.target.value)
									if (validatePhone(event.target.value)) {
										setButtonDissabled(true);
									} else {
										setButtonDissabled(false);
									}
								}}
								label={`טלפון`}
							/>
						</Grid>
						<Grid item container flexDirection="column" justifyContent="space-between" style={{ height: "50px", marginBottom: "10px" }}>
							<ActionButton textLabel={`חיפוש חדש`} dissable={buttonDissabled} onClick={() => {
								setPhone("");
								setHawb("");
								setSelectedShipping(null);
							}} style={{ height: "40px" }} />
							<ActionButton textLabel={`הצג`} onClick={() => {
								if (phone && hawb) {
									if (phone.length > 0 && hawb.length == 8) {
										var data = { phone: phone, hawb: hawb }
										getParcelStatusAPI(data, function (dt) {
											resetParams(dt);
										});
									}
								}
							}} style={{ height: "40px"}} />
						</Grid>
					</Grid>}
					{selectedShipping && <Grid container item key={selectedShipping.id} style={{ marginBottom: '24px' }}>
						{selectedShipping?.error && <Grid container item lg={12} className='label-wrapper' >
							<Grid item container style={{direction: "rtl", fontSize:"18px", color: "red"}}>{selectedShipping?.error} </Grid>
						</Grid>
						}
						{selectedShipping?.params?.couponText && <Grid container item lg={12} className='label-wrapper' >
							<Grid>Coupon: {selectedShipping?.params?.couponText || ""} </Grid>
						</Grid>
						}

						<Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ direction: 'rtl' }}>
							{selectedShipping?.params?.statusLog && <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{}}>
								
								{true && <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ }}>
									<Grid flexWrap="nowrap" container className="screen-progress-steps" style={{ justifyContent: 'flex-start' }}>
										<Grid item container flexDirection="column" className={`progress-step1 ${(getStatusById(selectedShipping.parentId == 0 ? 1 : 2) ? 'done_back' : '')}`} style={{ marginTop: "10px" }}>
											<Grid className={`step-circle ${(getStatusById(selectedShipping.parentId == 0 ? 1 : 2) ? 'red_back' : '')}`}>
												{getStatusById(selectedShipping.parentId == 0 ? 1 : 2) ? <FaCheck /> : 1}
											</Grid>
											<Grid className="step-title" style={{ textAlign: "inherit" }}>
												תשלום
											</Grid>

										</Grid>
										{/*<hr class="divider" />*/}
										<Grid item container flexDirection="column" className={`progress-step1 ${(getStatusById(2) ? 'done_back' : '')}`} style={{ marginTop: "10px" }}>
											<Grid className={`step-circle ${(getStatusById(2) ? 'red_back' : '')}`} >
												{getStatusById(2) ? <FaCheck /> : 2}
											</Grid>
											<Grid className="step-title" style={{ textAlign: "inherit" }}>
												המשלוח בטיפול
											</Grid>
										</Grid>
										{/* <hr class="divider" />*/}
										<Grid item container flexDirection="column" className={`progress-step1 ${(getStatusById(3) ? 'done_back' : '')}`} style={{ marginTop: "10px" }}>
											<Grid className={`step-circle ${(getStatusById(3) ? 'red_back' : '')}`}>
												{getStatusById(3) ? <FaCheck /> : 3}
											</Grid>
											<Grid className="step-title" style={{ textAlign: "inherit" }}>
												{/*{`בדרך לארץ` }*/}
												{/*{`בדרך לטיסה` }*/}
												{`בדרך לארץ`}
											</Grid>
										</Grid>
										<Grid item container flexDirection="column" className={`progress-step1 ${((getStatusById(5) || getStatusById(6)) ? 'done_back' : '')}`} style={{ marginTop: "10px" }}>
											<Grid className={`step-circle ${((getStatusById(5) || getStatusById(6)) ? 'red_back' : '')}`}>
												{((getStatusById(5) || getStatusById(6))) ? <FaCheck /> : 4} {/*5*/}
											</Grid>
											<Grid className="step-title" style={{ textAlign: "inherit" }}>
												נמסר להפצה
											</Grid>
										</Grid>
										{/*<hr class="divider" />*/}
										<Grid item container flexDirection="column" className={`progress-step1 ${(getStatusById(7) ? 'done_back' : '')}`} style={{ marginTop: "10px" }}>
											<Grid className={`step-circle ${(getStatusById(7) ? 'red_back' : '')}`}>
												{getStatusById(7) ? <FaCheck /> : 5} {/*6*/}
											</Grid>
											<Grid className="step-title" style={{ textAlign: "inherit" }}>
												משלוח נמסר
											</Grid>
										</Grid>
									</Grid>
								</Grid>}
								<Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ mt: '2vh !important' }}>
									<Grid container item lg={3} xs={3} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
										תאריך
									</Grid>
									<Grid container item lg={9} xs={9} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
										תיאור
									</Grid>
								</Grid>
								{selectedShipping?.params?.statusLog?.map((val, index) => <>
									{(val.DESC && (val.id == 3 && selectedShipping?.parentId == 0 ? selectedShipping?.params?.shippingStatus : true)) && <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ borderBottom: '1px solid gray', mt: '2vh !important' }}>
										<Grid container item lg={3} xs={3} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
											{getDateFormat(val.DATE)}
										</Grid>
										<Grid container item lg={9} xs={9} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
											{val.DESC}
										</Grid>
									</Grid>
									}
								</>)}
								{selectedShipping.parentId == 0 && <Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ borderBottom: '1px solid gray', mt: '2vh !important' }}>
									<Grid container item lg={3} xs={3} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
										{getDateFormat(selectedShipping.create_date)}
									</Grid>
									<Grid container item lg={9} xs={9} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
										{`ההזמנה נקלטה במערכת`} {selectedShipping?.parentId == 0 ? " - ORCA Market" : ""}
									</Grid>
								</Grid>}
							</Grid>}
							{selectedShipping.consolidationStatus == 3 &&

								<Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ borderBottom: '1px solid gray', mt: '2vh !important' }}>
									<Grid container item lg={3} xs={3} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
										איחוד חבילות:
									</Grid>
									<Grid container item lg={9} xs={9} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
										{selectedShipping?.params?.notes?.replace("Repack:", "")}
									</Grid>
								</Grid>
							}
							{selectedShipping.repackStatus == 104 &&

								<Grid container item lg={12} justifyContent="flex-start" alignContent="flex-start" sx={{ borderBottom: '1px solid gray', mt: '2vh !important' }}>
									<Grid container item lg={3} xs={3} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
										איחוד חבילות:
									</Grid>
									<Grid container item lg={9} xs={9} justifyContent="flex-start" alignContent="flex-start" sx={{ fontWeight: 'bold' }}>
										{selectedShipping?.params?.notes?.replace("Repack:", "")}
									</Grid>
								</Grid>
							}
						</Grid>
					</Grid>}

				</Grid>
			</Grid>
		</Fragment>
	);
}

export default Status;